import '../../styles/pages/fall-winter-2024/cracking-the-code-on-criminal-minds.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2024-stories/more-stories"
import Gallery from "react-photo-gallery";
import Vimeo from '@u-wave/react-vimeo';
import { Parallax, Background } from 'react-parallax';



import Hero from '../../images/fall-winter-2024/cracking-the-code-on-criminal-minds/story1-hero-1920x1080.jpg';
import socialBanner from "../../images/fall-winter-2024/social-banners/story1-social-1200x630.jpg";
import titelGraphic from "../../images/fall-winter-2024/cracking-the-code-on-criminal-minds/story1-title-380x245.svg";
import ctaBanner from '../../images/fall-winter-2024/cracking-the-code-on-criminal-minds/story1-cta-1920x515.jpg';

import criminalmindsIMG1 from '../../images/fall-winter-2024/cracking-the-code-on-criminal-minds/story1-01-533x800.jpg';
import criminalmindsIMG2 from '../../images/fall-winter-2024/cracking-the-code-on-criminal-minds/story1-01-800x533.jpg';
import criminalmindsIMG3 from '../../images/fall-winter-2024/cracking-the-code-on-criminal-minds/story1-02-800x533.jpg';
import criminalmindsIMG4 from '../../images/fall-winter-2024/cracking-the-code-on-criminal-minds/story1-01-3815x1355.jpg';



export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'Cracking the Code on ‘Criminal Minds’';
    var pageDesc = 'Theatre arts alum Kirsten Vangsness plays Penelope Garcia in the hit CBS show and Paramount+ spinoff.';
    var slug = 'cracking-the-code-on-criminal-minds';

    var heroStyles = {
      backgroundImage: `url(${Hero})`,
    }

    const photos = [
      {
        src: criminalmindsIMG1,
        width: 533,
        height: 800,
        alt: '',
        title: ''
      },
      {
        src: criminalmindsIMG2,
        width: 800,
        height: 533,
        alt: '',
        title: ''
      },
      {
        src: criminalmindsIMG3,
        width: 800,
        height: 533,
        alt: '',
        title: ''
      },


    ];

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2024">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`fall-winter-2024 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s"><img className='titelGraphic shadow' src={titelGraphic} alt='Cracking the Code on ‘Criminal Minds' /></h1>
            <p className="sub-heading animated fadeInDown delay-1s">Theatre arts alum Kirsten Vangsness plays Penelope Garcia in the hit CBS show and Paramount+ spinoff.
</p>
            <span className="animated fadeInDown delay-1s byline">By Taylor Arrey </span>
          </div>
          {/* <div className="tint"></div> */}
        </div>
        <article className='wrap' id="copy-wrap">

          <div className='wrap small'>

          



          <figure className='align-right large align-outside'>
      <img src={criminalmindsIMG2} alt="" />
      <figcaption>Kirsten Vangsness, CSUF theatre arts alum, on the set of “Criminal Minds” (Courtesy of Kirsten Vangsness)</figcaption>
</figure>
<p>When Kirsten Vangsness showed up to the “Criminal Minds” set on her first day, her character had two lines in the pilot episode.</p>



<p>“We were never supposed to see her again,” recalls Vangsness.</p>

<p>That was before she brought Penelope Garcia to life.</p>

<p>Vangsness plays everyone’s favorite FBI computer genius on the hit CBS show “Criminal Minds” and Paramount+’s original spinoff series, “Criminal Minds: Evolution.” </p>



<p>She credits Cal State Fullerton for helping her land the iconic role that has transformed into a 17-season — and counting — career. </p>

<p>“One of the reasons they kept bringing me back is because I did all of my scenes in one take. I was able to do that because I’m a theatre actress who trained at Cal State Fullerton,” says Vangsness ’96 (B.A. theatre arts).</p>

<ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <figure className="align-wide">
            <Vimeo
              video="1017591145"
              autoplay={false}
              responsive={true}
              showPortrait={false}
              showTitle={false}
              showByline={false}
            />
          </figure>
          </ScrollAnimation>
<br></br>          
<h2>A ‘Fierce Bravery’ on Stage</h2>

<p>There was never any question that Vangsness loved acting.</p>

<p>“It’s all I wanted to do,” she says.</p>

<p>But she wasn’t sure how to turn her fire for performing into a career. It was Vangsness’ mom, a public school teacher, who encouraged her to apply to CSUF and emphasized the value of higher education.</p>

{/* <blockquote className='align-center large align-outside'>
  <p>Never in my wildest dreams did I think I was going to be a professional actress. <span className='left-quote'>”</span></p>
  <cite>Kirsten Vangsness ’96 (B.A. theatre arts)</cite>
</blockquote> */}

<p>“Never in my wildest dreams did I think I was going to be a professional actress,” says Vangsness, who transferred from Cypress College. “When I realized that I was going to be a theatre arts major at Cal State Fullerton, my goals seemed more legitimate.” </p>

<p>Vangsness developed her talent in the <a href="https://www.fullerton.edu/college-of-arts/" title="College of the Arts" target="_blank">College of the Arts</a>. She says the combination of academic courses with hands-on professional training not only bolstered her acting portfolio, but provided her with an invaluable support system. </p>

<p>Her instructors, each with their own professional backgrounds in the industry, emphasized that there isn't just one way to succeed in acting. </p>

<p>“What I loved about my experience at Cal State Fullerton is that my professors challenged me to learn different techniques,” says Vangsness. “To me, that's the hallmark of a really good education.” </p>

</div>
</article>
<Parallax
          bgImage={criminalmindsIMG4}
          bgImageAlt=""
          strength={200}
          style={{margin: 0}}
          className='full-width'>
            <div className="caption">
            Kirsten Vangsness performs at CSUF’s 2013 Concert Under the Stars event (Matt Gush/CSUF)
            </div>
            <div className='height' />
</Parallax>

<article className='wrap' id="copy-wrap-no-margin">

<div className='wrap small' style={{marginTop: '2em'}}>


<blockquote className='align-center large align-outside'>
  <p>When I realized that I was going to be a theatre arts major at Cal State Fullerton, my goals seemed more legitimate. <span className='left-quote'>”</span></p>
  <cite>Kirsten Vangsness ’96 (B.A. theatre arts)</cite>
</blockquote>

<p>Even after graduating, the CSUF community continued to make a difference in her career. Eve Himmelheber, professor of theatre and dance, was so impressed by Vangsness’ “fierce bravery” on stage that she invited the alum to play the lead in a production she was directing. </p>



<p>“She is a creative force and a versatile artist who is quick witted, improvisational, disciplined and friendly,” says Himmelheber.</p>

<p>After starring in that production of "The Heidi Chronicles” at Rio Hondo College, Vangsness knew beyond a doubt that she wanted to be a professional actress. </p>

<p>“When I was on stage, it was like everything inside me woke up, and I knew I had to do this,” says Vangsness.   </p>



<h2>Guarding Penelope Garcia</h2>

<figure className='align-right small align-outside'>
      <img src={criminalmindsIMG1} alt="" />
      <figcaption>Kirsten Vangsness plays Penelope Garcia (Courtesy of Kirsten Vangsness)</figcaption>
</figure>

<p>Guided by her love for performing, Vangsness landed theatrical roles in productions across Orange County and Los Angeles, eventually finding her way to FBI Quantico in “Criminal Minds.”  </p>

<p>The award-winning series follows the FBI’s Behavioral Analysis Unit as they profile and catch the nation’s most notorious serial killers. Garcia, the team’s technical analyst, uses her expert hacking skills to track down these unknown subjects, or “unsubs,” while providing the show with a lightness that shines through her kind, bubbly and confident personality.  </p>

<p>“It's the joy of my life,” says Vangsness. “The greatest thing I have yet to do in my professional career is to be Penelope’s guardian.”   </p>



<p>Since the show&rsquo;s debut in 2005, Vangsness has had the  opportunity to not only develop Garcia&rsquo;s character through hundreds of complex  storylines, but she&rsquo;s also been able to write a few episodes while working  alongside a star-studded cast.</p>
<p>&ldquo;I&rsquo;m really proud of her,&rdquo; says Vangsness. &ldquo;She&rsquo;s a  derivative of characters like Miss Moneypenny in &lsquo;James Bond&rsquo; and Pauley  Perrette in &lsquo;NCIS.&rsquo; To be part of that canon of female characters is really  powerful.&rdquo;</p>



<figure className='align-wide'>
      <img src={criminalmindsIMG3} alt="" />
      <figcaption>Kirsten Vangsness, from left to right, with Aisha Tyler, Adam Rodriguez, A.J. Cook and Joe Mantegna (Courtesy of Kirsten Vangsness)</figcaption>
</figure>

<p>Vangsness will continue to play the sunny computer genius in  &ldquo;Criminal Minds: Evolution,&rdquo; which was renewed for a third season earlier this  year. And while the upcoming season includes twists and turns, Vangsness says  fans can trust that she will always protect the Garcia they know and  love.</p>


<p>&ldquo;She&rsquo;s someone who doesn&rsquo;t dictate the way she feels about  herself by what society thinks,&rdquo; Vangsness says. &ldquo;I think there are people who  want Garcia to grow up and face reality, but she&rsquo;s never going to. There are a  lot of women like Garcia in the world, and the fact that I can capture them on  camera is fantastic.&rdquo; <span className="end-mark" /></p>



          
          </div>
        </article>
        <div className='cta-banner' style={{ backgroundImage: `url(${ctaBanner})` }}>
          <div className='cta-text'>
            <p>
              <span className='cta-title'>Support the College of the Arts</span><br />
              <a href="https://give.fullerton.edu/titanmag-arts" target='_blank' rel='noreferrer'>give.fullerton.edu/titanmag-arts</a>
            </p> 
          </div>
        </div>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 